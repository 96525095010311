<template>
  <div class="app-content content ">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
            <div class="row breadcrumbs-top">
                <div class="col-12">
                    <h2 class="content-header-title float-start mb-0">{{ item.profile_name ? item.profile_name : '' }} <br>
                      <small>MEMOIR {{ item.profile ? item.profile.year : (activeMemoir ? activeMemoir.year : '') }}</small>
                    </h2>
                </div>
            </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <!-- ESTO VA A CAMBIAR -->
            <a
              class="btn btn-icon btn-secondary mx-50"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back "
              @click="$router.push({name: 'researcher.memoir-highlight.index'})"
            ><i data-feather="chevron-left" /></a>
            <!-- ESTO VA A CAMBIAR -->
            <a
              class="btn btn-icon btn-primary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Edit"
              @click="$router.push({name: 'researcher.memoir-highlight.edit', params: { id: item.id }})"
            ><i data-feather="edit-3" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-sm-4">
            <div
              v-if="item.profile"
              class="card"
            >
              <div class="card-content collapse show">
                <div class="card-body">
                  <h3 class="mb-2">Author</h3>
                    <img
                      :src="item.profile.image_url"
                      :alt="item.profile.alt_text"
                      class="img-fluid"
                    >
                  <hr>
                  <h3>{{ item.profile_name }}</h3>
                  <h5>{{ item.profile.area ? item.profile.area.name : '--' }}</h5>
                  <hr v-if="(loggedUser.roles.includes('super-admin') && item.status) || item.special_access || item.proof_access">
                  <template v-if="item.special_access">
                    <p><span class="badge bg-light-danger">Special access</span></p>
                  </template>
                  <template v-if="item.proof_access">
                    <p><span class="badge bg-light-info">Memoir proof access</span></p>
                  </template>
                  <template v-if="loggedUser.roles.includes('super-admin') && item.status">
                    <p><strong>Memoir status</strong></p>
                    <p
                      v-html="item.status.status_table"
                    />
                  </template>
                </div>
              </div>
            </div>
            <template
              v-if="item.coAuthors && item.coAuthors.length > 0"
            >
              <div
                v-for="(aut, index) in item.coAuthors"
                :key="index"
                class="card"
              >
                <div class="card-content collapse show">
                  <div class="card-body">
                    <h3 class="mb-2">Coauthor</h3>
                    <img :src="aut.avatarUrl" alt="profile image coauthor" class="img-fluid">
                    <hr>
                    <h3>{{ aut.true_name }}</h3>
                    <h5>{{ aut.areas && aut.areas.length > 0 ? aut.areas[0].name : '' }}</h5>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div class="col-sm-8">
            <div
              v-if="item.text"
              class="card"
            >
                <div class="card-header">
                    <h3 class="cart-title"><a data-action="collapse">Content</a></h3>
                    <div class="heading-elements">
                        <ul class="list-inline mb-0">
                            <li>
                                <a data-action="collapse"><i data-feather="chevron-down"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="card-content collapse show">
                    <div class="card-body">
                        <div class="row">
                            <div class="col" v-html="item.text" />
                        </div>
                    </div>
                </div>
            </div> 

            <div
              v-if="item.image_1 || item.image_2 || item.image_3"
              class="card"
            >
                <div class="card-header">
                    <h3 class="cart-title"><a data-action="collapse">Images</a></h3>
                    <div class="heading-elements">
                        <ul class="list-inline mb-0">
                            <li>
                                <a data-action="collapse"><i data-feather="chevron-down"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="card-content collapse show">
                  <div class="card-body">
                    <div class="row">
                      <div
                        v-if="item.image_1"
                        class="col"
                      >
                        <img
                          :src="item.image_1.url"
                          class="img-fluid w-50"
                          alt="Memoir highlight image 1"
                        >
                        <hr>
                        <p v-html="item.image_1_caption" />
                      </div>
                    </div>
                    <div class="row">
                      <div
                        v-if="item.image_2"
                        class="col"
                      >
                        <img
                          :src="item.image_2.url"
                          class="img-fluid w-50"
                          alt="Memoir highlight image 2"
                        >
                        <hr>
                        <p v-html="item.image_2_caption" />
                      </div>
                    </div>
                    <div class="row">
                      <div
                        v-if="item.image_3"
                        class="col"
                      >
                        <img
                          :src="item.image_3.url"
                          class="img-fluid w-50"
                          alt="Memoir highlight image 3"
                        >
                        <hr>
                        <p v-html="item.image_3_caption" />
                      </div>
                    </div>
                  </div>
                </div>
            </div> 
            <div class="card">
                <div class="card-header">
                    <h3 class="cart-title"><a data-action="collapse">References</a></h3>
                    <div class="heading-elements">
                        <ul class="list-inline mb-0">
                            <li>
                                <a data-action="collapse"><i data-feather="chevron-down"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="card-content collapse show">
                    <div class="card-body">
                        <div class="row">
                            <div class="col" v-html="item.references" />
                        </div>
                    </div>
                </div>
            </div> 
          </div>
        </div>

        <!-- <InternalNotes
          :notes="item.notes"
          @saveNewNote="saveNewNote"
        /> -->
        <br>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { BCollapse } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import InternalNotes from '@/views/back/partials/components/InternalNotes.vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  components: {
    vSelect,
    DatePicker,
    BCollapse,
    InternalNotes,
  },
  data() {
    return {
      highlightId: this.$route.params.id,
      icon_path_1: '',
      icon_path_2: '',
      icon_path_3: '',
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      item: 'memoirHighlights/item',
      loggedUser: 'auth/admin',
      activeMemoir: 'memoirs/activeMemoir',
    }),
  },
  async mounted() {
    this.loading = true
    if (this.highlightId) {
      await this.$store.dispatch("memoirHighlights/fetchId", this.highlightId);
      this.icon_path_1 = this.item.image_1 ? this.item.image_1.url : null;
      this.icon_path_2 = this.item.image_2 ? this.item.image_2.url : null;
      this.icon_path_3 = this.item.image_3 ? this.item.image_3.url : null;
    }
    this.loading = false
  },
}
</script>